.root {
  padding-block-end: 20px;
}

.form {
  align-items: center;
  display: flex;
  height: 44px;
  position: relative;
  z-index: 1;
}

.form button {
  max-width: 252px;
}

.content .row {
  border-radius: 0;
}

.content .row:first-of-type {
  border-top-left-radius: var(--border-radius-card);
  border-top-right-radius: var(--border-radius-card);
}

.content .row:last-of-type {
  border-bottom-left-radius: var(--border-radius-card);
  border-bottom-right-radius: var(--border-radius-card);
}
