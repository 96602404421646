.root p {
  margin: 0;
}

.header {
  border-block-end: 1px solid var(--color-border);
}

.header,
.content,
.footer {
  padding: var(--spacing-16) var(--spacing-24);
}

.hintContent {
  padding-inline: var(--spacing-24);
  padding-block-end: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.title {
  font-size: var(--font-size-lg);
}

.content {
  display: grid;
  gap: var(--spacing-32);
}

.footer {
  display: flex;
  gap: var(--spacing-16);
}

.total {
  margin: 0;
  font-weight: normal;
}

.description {
  margin-inline-start: 0;
}
