.header {
  display: grid;
  gap: var(--spacing-16);
  margin-block-end: var(--spacing-16);
}

.title {
  display: flex;
  gap: var(--spacing-16);
  align-items: center;
}
