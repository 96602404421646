.slide {
  padding: 0;
  background: var(--color-bg-card);
  height: 100vh;
}

.employee {
  display: flex;
  align-items: center;
  box-shadow: inset 0 1px 0 0 var(--color-border);
  padding-inline: 20px;
  padding-block: 18px;
  transition: ease background 0.15s;
}

.avatar {
  margin-inline-end: var(--spacing-08);
}

.link:hover {
  background: var(--color-controls-hover-bg);
  text-decoration: none;
}

.link:focus {
  box-shadow: var(--focus-border);
  text-decoration: none;
}

.email {
  color: var(--color-text-tertiary);
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.emailWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bouncedEmail {
  color: var(--color-status-negative);
}

.inview {
  height: 1px;
}
