.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-start;
  padding: 60px;
}

.title {
  margin-inline: 0;
  margin-block: 20px 9px;
  font-size: rem(27px);
  font-weight: var(--font-weight-bold);
}

.text {
  font-size: var(--font-size-md);
  margin-block-start: 0;
}

.text + button {
  margin-block-start: 17px;
}

.empty .content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 560px;
  text-align: center;
  width: 100%;
}
