.container {
  box-shadow: inset 0 -1px 0 0 var(--color-border);
  display: flex;
  height: 100%;
  padding-inline: 16px 12px;
  padding-block: 10px 8px;
  width: 100%;
}

.container.links {
  padding-inline-start: 6px;
}

.content,
.meta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  padding-inline-start: 6px;
  max-width: 200px;
}

.content > p {
  margin: 0;
}

.content.context {
  justify-content: center;
}

.content.context:hover > a {
  text-decoration: none;
}

.toggleButton {
  position: relative;
  height: 1rem;
  padding: 1px;
}

.chevron {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
}

.container.expanded .chevron {
  transform: rotate(90deg);
}

[dir='rtl'] .chevron {
  transform: rotate(180deg);
}

.name {
  color: var(--color-text-primary);
  display: block;
  height: 18px;
  margin-block-end: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.footer {
  align-items: flex-end;
  display: inline-flex;
}

.meta {
  align-items: flex-end;
  flex-direction: column;
  flex-grow: 1;
}

.meta.count {
  justify-content: flex-end;
}

.meta.classification {
  justify-content: space-between;
}

.size {
  flex-direction: row-reverse;
}

.size span {
  margin-inline: 0 2px;
}

[dir='rtl'] .size span {
  margin-inline: 2px 0;
}
