:root {
  --course-height: 273px;
}

.container {
  display: flex;
  flex-direction: column;
}

.course {
  background-position: center;
  background-size: cover;
  border: 5px solid var(--color-bg-card);
  border-radius: 1px;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.35),
    0 8px 0 -4px var(--color-bg-card),
    0 8px 1px -3px rgba(0, 0, 0, 0.15),
    0 16px 0 -8px var(--color-bg-card),
    0 16px 1px -7px rgba(0, 0, 0, 0.15);
  color: var(--color-text-primary);
  display: flex;
  flex-direction: column;
  min-height: var(--course-height);
  transition:
    box-shadow 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.course:hover,
.course:focus {
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.35),
    0 10px 0 -4px white;
  outline: none;
  transform: scale(1.06);
  z-index: 4;
}

.course:hover,
.course:active,
.course:visited {
  color: var(--color-text-primary);
  text-decoration: none;
}

.header {
  display: flex;
  padding: 10px;
}

.content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  font-size: var(--font-size-2xs);
  position: relative;
  font-weight: var(--font-weight-bold);
}

.completed .content {
  background: rgba(41, 67, 75, 0.5);
}

.completed .icon img {
  display: none;
}

.completedWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  inset-block: 45px 0;
  inset-inline: 0;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-inverted);
}

.meta {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 10px;
  min-height: 110px;
  width: 100%;
  margin-block-start: auto;
}

.meta > div {
  margin-block-start: auto;
}

.metaItem {
  align-items: center;
  display: flex;
  margin-inline-end: 10px;
}

.metaIcon {
  color: var(--color-text);
  margin-inline-end: 5px;
}

.metaIcon.clockIcon path {
  stroke: none;
}

.metaDriverText {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  margin-block-end: 5px;
}

.metaDriverTitle {
  font-size: rem(13px);
  font-weight: var(--font-weight-bold);
}

.metaLesson {
  display: flex;
  margin-block-start: 5px;
}

.cardFooter,
.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardFooter {
  margin-block-start: 25px;
  text-align: center;
}

.footerText {
  align-items: center;
  display: flex;
  padding-inline: 6px;
  padding-block: 0;
}

.footerText span {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  padding-inline-start: 5px;
  color: var(--color-text-primary);
}

.progress {
  display: flex;
}

.resumeIcon {
  height: 28px;
  width: 28px;
}

[dir='rtl'] .resumeIcon {
  transform: scaleX(-1);
}

.title {
  font-size: var(--font-size-sm);
  margin-block-start: 5px;
  font-weight: var(--font-weight-bold);
}

.start {
  padding-inline: 10px;
  padding-block: 0 10px;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon img {
  max-height: 60px;
}

.driver {
  font-size: var(--font-size-2xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
}
