.section {
  border-block-end: 1px solid var(--color-border);
  padding-inline: 0;
  padding-block: var(--spacing-24);
}

.infoCard {
  margin-block: var(--spacing-16);
}

.cardInner {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-16);
  gap: var(--spacing-16);
}

.header {
  margin-block: var(--spacing-32) var(--spacing-08);
}

.fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
