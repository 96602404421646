:root {
  --side-width: rem(280px);
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.main {
  align-items: stretch;
  display: flex;
  flex: 2;
  max-height: 100%;
  overflow: hidden;
}

.container {
  overflow: auto;
  flex: 2;
  border-inline-start: 1px solid var(--color-border-section);
}

.side {
  width: var(--side-width);
  box-shadow: 2px 0 0 0 rgb(0 0 0 / 2%);
  overflow: visible auto;
  text-align: end;
  transition: all 0.2s;
}

.side.hidden {
  width: rem(40px);
  overflow: unset;
}

.side ul {
  width: var(--side-width);
}

.hideButtonWrapper {
  padding: var(--spacing-04);
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  justify-content: flex-end;
}

.showIconWrapper {
  height: fit-content;
  padding: var(--spacing-04);
  padding-block: var(--spacing-08) rem(10px);
  border-block-end: 1px solid transparent;
}

.loader {
  padding-block-start: rem(100px);
}

.mobileShowAttributesBtn {
  padding: var(--spacing-16);
  padding-block-end: 0;
}
