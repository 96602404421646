@import '../../styles/variables.css';

.root {
  padding-inline: 20px;
  padding-block: 24px;
}

.title {
  margin-block-end: 0;
}
