.root {
  cursor: pointer;
  transition: ease all 0.25s;
}

.container {
  padding: 15px;
}

.header {
  display: flex;
}

.driver {
  padding-inline: 0 0;
  padding-block: 15px 5px;
}

.title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  overflow: hidden;
}

.text {
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
  padding-inline: 0 0;
  padding-block: 10px 0;
  overflow: hidden;
}

.footer {
  margin-block-start: auto;
}

.actionLabel {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  padding-inline-start: 10px;
}

.categoryName {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.driverName {
  font-size: var(--font-size-2xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
}
