.root {
  border-block-start: 1px solid var(--color-border-section);
}

.mainPart {
  display: flex;
  justify-content: space-between;
  border-block-end: 1px solid var(--color-border-section);
}

@media (--handhelds) {
  .mainPart {
    flex-direction: column;
  }
}

.expandStrip {
  display: flex;
}

.prioritiesListRoot {
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  flex-direction: column;
}

.prioritiesArrow {
  margin-inline: var(--spacing-16) var(--spacing-08);
  margin-block: auto;
}

.prioritiesLabel {
  margin-block: var(--spacing-12);
  color: var(--color-text-tertiary);
  line-height: initial;
}

.prioritiesLabel:hover {
  color: var(--color-text-primary);
}

.expandButton {
  position: relative;
  display: flex;
  align-items: center;
}

.addMore {
  color: var(--color-text-link);
  margin-block: var(--spacing-08);
  line-height: initial;
}

.addMore:hover {
  color: var(--color-text-link);
}

.categoryNameRoot {
  width: 34%;
  display: flex;
  background-color: var(--color-bg-card);
}

@media (--handhelds) {
  .categoryNameRoot {
    width: 100%;
  }
}

.categoryNameRoot:hover {
  background: var(--color-bg-info);
}

.categoryNameRoot:hover .arrow {
  margin-inline-end: 0;
}

.arrow {
  margin-inline-end: 30px;
  margin-block: auto;
}

.connectorRoot {
  display: flex;
  flex: 2;
}

.categoryName {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.categoryName > div {
  display: flex;
  height: 33%;
}

.categoryName > span {
  height: 33%;
}

.setByLabel {
  margin-block: auto;
  margin-inline-start: var(--spacing-24);
  padding-block-start: var(--spacing-08);
  color: var(--color-text-tertiary);
}

@media (--handhelds) {
  .setByLabel {
    margin-inline-start: var(--spacing-16);
    margin-block: var(--spacing-16) var(--spacing-08);
  }
}

.improveScore {
  margin-inline-start: var(--spacing-24);
  justify-content: space-between;
}

.improveScore p {
  margin-block: auto;
}

@media (--handhelds) {
  .improveScore {
    justify-content: unset;
    margin-block: calc(var(--spacing-08) * -1) var(--spacing-08);
    margin-inline-start: var(--spacing-48);
  }
}

.link > div {
  display: flex;
}

.link,
.link:hover,
.link:focus {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  flex: 2;
}

.link:hover .arrow {
  margin-inline-end: 20px;
}

.link:hover .prioritiesLabel {
  color: var(--color-text-primary);
}

.iconAndText {
  display: flex;
  margin-block: auto;
}

.iconAndText span {
  width: -moz-fit-content;
  width: fit-content;
}

@media (--handhelds) {
  .iconAndText {
    width: 100%;
    margin-inline-start: var(--spacing-08);
  }

  .iconAndText p {
    border-block-end: unset;
  }
}

.priorityIcon {
  width: var(--spacing-32);
  height: 100%;
  position: absolute;
  inset-inline-start: 0;
  transform: translateX(-50%);
}
