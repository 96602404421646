@import '../../../../../../styles/variables.css';
@import './variables.css';

.container {
  background-color: var(--color-bg-card);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  z-index: 2;
}

.container table {
  width: 100%;
  min-height: 200px;
}

.container table thead {
  /* overwrite react-virtuoso styling */
  inset-block-start: rem(83px) !important;
  /* 1 more than --peak-menu-z-index: 1000; */
  z-index: var(--thead-z-index-container-width) !important;
}

.container table tr {
  display: flex;
  border-block-end: 1px solid var(--color-border);
}

.container table td {
  display: inline-flex;
}

.container table th[scope='row'] {
  font-weight: normal;
  text-align: start;
}

.noResults {
  padding: var(--spacing-16);
}

.paddingBlockEnd {
  padding-block-end: var(--spacing-64);
}

.overflowAuto {
  height: 100%;
  overflow-x: auto;
}

.overflowAuto table thead {
  /* overwrite react-virtuoso styling */
  inset-block-start: 0 !important;
}

.wrapper {
  background-color: var(--color-bg-primary);
  padding: var(--spacing-16);
}

.alert {
  background-color: var(--color-bg-primary);
  width: 100%;
  padding: var(--spacing-16);
}

.spinner {
  color: var(--color-text-secondary);
  display: flex;
  justify-content: center;
  padding: var(--spacing-08);
}
