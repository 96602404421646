.section {
  max-width: 1100px; /* 4 cards + 3 gutters = (4*250)+(3*20) = 1000 + 60 */
  margin-inline: auto;
  margin-block: 0;
  overflow: hidden;
}

.sectionHeader {
  margin-inline: 15px;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 3px; /* make sure custom outline is shown */
}

.sectionWrapper {
  width: 100%;
}

.sectionContent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: initial;
}

@media screen and (min-width: 947px) {
  .sectionContent {
    justify-content: space-between;
  }
}

.sectionContent.noWrap.isTouch {
  flex-wrap: nowrap;
}

/* Add spacing between items*/
.sectionItem {
  padding-inline: 10px;
  padding-block: 5px;
}

@media screen and (max-width: 1375px) {
  .sectionItem:nth-child(3n + 2):last-child {
    margin-inline-end: calc((100% - 550px) - ((100% - 830px) / 2));
  }
}

@media screen and (min-width: 1376px) {
  .sectionItem:nth-child(4n + 2):last-child {
    margin-inline-end: calc((100% - 550px) - ((100% - 1110px) / 3));
  }

  .sectionItem:nth-child(4n + 3):last-child {
    margin-inline-end: calc((100% - 830px) - ((100% - 1110px) / 1.5));
  }
}

.noWrap .sectionItem:last-child {
  margin-inline-end: 0;
}

@media screen and (max-width: 1376px) {
  .noWrap .sectionItem:nth-child(3) ~ .sectionItem {
    display: none;
  }
}
