:root {
  --resource-line-height: 18px;
  --resource-max-height: 36px;
}

.link {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--color-text-primary);
  text-decoration: none;
}

.link:hover,
.link:active,
.link:visited,
.link:focus {
  text-decoration: none;
  outline: 3px solid transparent;
}

.image {
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-card) var(--border-radius-card) 0 0;
  height: 100%;
  width: 100%;
}

.image::before {
  content: '';
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.02) 4%,
    rgba(0, 0, 0, 0.04) 9%,
    rgba(0, 0, 0, 0.06) 14%,
    rgba(0, 0, 0, 0.08) 19%,
    rgba(0, 0, 0, 0.09) 24%,
    rgba(0, 0, 0, 0.12) 30%,
    rgba(0, 0, 0, 0.14) 35%,
    rgba(0, 0, 0, 0.17) 41%,
    rgba(0, 0, 0, 0.19) 47%,
    rgba(0, 0, 0, 0.21) 53%,
    rgba(0, 0, 0, 0.24) 59%,
    rgba(0, 0, 0, 0.26) 65%,
    rgba(0, 0, 0, 0.28) 70%,
    rgba(0, 0, 0, 0.31) 76%,
    rgba(0, 0, 0, 0.33) 81%,
    rgba(0, 0, 0, 0.35) 86%,
    rgba(0, 0, 0, 0.37) 91%,
    rgba(0, 0, 0, 0.38) 96%,
    rgba(0, 0, 0, 0.4)
  );
}

.driver {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  margin-block-end: 5px;
  text-transform: uppercase;
}

.header {
  height: 140px !important;
  position: relative;
}

.title {
  font-size: var(--font-size-2xs);
  width: 100%;
}

.icon {
  display: inline-block;
  margin-inline: 0;
  margin-block: -13px 0;
  position: relative;
  inset-block-start: 7px;
  width: 24px;
  height: 24px;
}

.icon svg path {
  fill: var(--color-viz-neutral);
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 130px;
  padding-inline: 15px;
  padding-block: 15px 0;
}

.label {
  position: absolute;
  inset-inline-start: 15px;
  inset-block-start: 15px;
  z-index: 1; /* ensure we're on top of the overlay */
}

.footer {
  margin-block-start: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer svg {
  width: 26px;
  height: 26px;
}

.footer span {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  padding-inline-start: 10px;
}

.source {
  position: absolute;
  inset-block-start: 92px;
  color: var(--color-text-primary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  margin: 10px;
  padding: 5px;
  max-width: calc(100% - 20px);
  background-color: var(--color-bg-secondary);
  border-radius: var(--border-radius-base);
}

.playIcon {
  position: absolute;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (--tablets-min) {
  .title {
    font-size: var(--font-size-sm);
  }
}
