.root {
  padding-inline: 0;
  padding-block: 20px var(--spacing-32);
}

.header {
  align-items: center;
  margin-block-end: var(--spacing-32);
}

.tooltip {
  color: var(--color-text);
  font-size: var(--font-size-base);
}

.sectionTitle {
  margin-block-end: var(--spacing-12);
}

.container {
  margin-block-end: 30px;
}

.row {
  align-items: stretch;
  border-block-end: 1px solid var(--color-border);
  display: flex;
  margin-block-end: 20px;
  padding-block-end: 20px;
}

.row:last-child {
  border-block-end: 0;
  margin-block-end: 0;
  padding-block-end: 0;
}

.row .withHelp {
  padding-block-end: 50px;
}

.section {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-start;
  position: relative;
}

.section:first-child {
  margin-inline-end: 10px;
}

.section:last-child {
  margin-inline-start: 10px;
}

.section:only-child {
  margin: 0;
}

.title {
  flex: 0 0 15px;
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
}

.description {
  color: var(--color-text-secondary);
  margin-block-end: 15px;
}

.options {
  display: flex;
  flex-basis: auto;
  flex-grow: 2;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
  justify-self: flex-end;
  position: relative;
}

.options label {
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.badgeTile {
  display: flex;
  flex-direction: column;
}

.scale {
  display: flex;
  justify-content: flex-end;
  margin-block-start: var(--spacing-16);
}

.scale label {
  min-height: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.scale > button {
  margin-inline-start: 8px;
  height: 42px;
  padding: 0.4375rem !important;
}

.scale div[role='radiogroup'] {
  width: 100%;
}

.customValueSettingRoot {
  align-items: center;
  display: flex;
  margin-block-start: 15px;
  min-height: 50px;
}

.customValueSettingRoot label {
  /* stylelint-disable-next-line csstools/use-logical */
  margin-bottom: 0 !important; /* override peak-components margin-bottom: 8px */
}

.customValueSettingRoot input {
  height: 42px;
  max-width: 65px;
  margin-inline: 15px 10px;
  margin-block: 0 0;
}

.customValueSettingRoot button {
  margin-inline-end: 5px;
}

.scaleRecommendations {
  align-items: flex-start;
  inset-block-end: -5px;
  display: flex;
  inset-inline-start: 0;
  position: relative;
  width: calc(100% - 55px);
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
}

.scaleHelpSmall {
  padding-inline-end: 10px;
  width: 25%;
}

.scaleHelpRecommended {
  width: 25%;
}

.scaleHelpLarge {
  width: 50%;
  text-align: end;
}

@media only screen and (max-width: 1120px) {
  .row {
    border-block-end: 0;
    flex-direction: column;
    margin-block-end: 0;
    padding-block-end: 0;
  }

  .section {
    border-block-end: 1px solid var(--color-border);
    margin-inline: 0 !important;
    margin-block: 0 20px !important;
    padding-inline: 0 !important;
    padding-block: 0 20px !important;
  }

  .row:last-child .section:last-child {
    border-block-end: 0;
  }

  .row .withHelp {
    padding-block-end: 0;
  }

  .row .withHelp .section {
    padding-block-end: 20px !important;
  }

  .row .withHelp .scaleRecommendations {
    width: calc(100% - 88px);
  }
}

@media (--handhelds-tablets) {
  .section {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
