.root {
  padding: var(--spacing-40);
}

@media (--handhelds) {
  .root {
    padding: 0;
  }
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: 0;
  margin-inline: auto;
  width: rem(310px);
}

@media (--handhelds) {
  .empty {
    width: auto;
    padding: rem(20px);
  }
}

.emptyTitle,
.emptySubtitle,
.copy {
  width: 100%;
}

.emptyTitle {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
  margin-block-end: var(--spacing-16);
  text-align: center;
}

.emptySubtitle {
  margin: 0;
  margin-block-end: rem(30px);
  text-align: center;
}

.tree.empty {
  width: rem(430px);
  padding-block-end: rem(30px);
}

@media (--handhelds) {
  .tree.empty {
    width: auto;
    padding-inline: var(--spacing-16);
  }
}

.emptyOrganizationIcon {
  margin-block: var(--spacing-40) var(--spacing-32);
}

.copyItem {
  align-items: flex-start;
  display: flex;
  margin-block-end: var(--spacing-16);
}

.copyIcon {
  margin-inline-end: var(--spacing-16);
}

.copyTitle,
.copyDescription {
  margin: 0;
}

.copyTitle {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
  margin-block-end: rem(3px);
}

.copyText {
  line-height: 1.2;
  width: 100%;
}

.emptyButton {
  margin-block-start: rem(20px);
  text-align: center;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (--handhelds) {
  .header {
    padding: var(--spacing-16) rem(20px);
  }
}

.mainTitle {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
  margin-block-end: 0;
}

.titleCount {
  color: var(--color-text-tertiary);
}

.mainLoader,
.loading {
  display: flex;
  justify-content: center;
}

.mainLoader {
  color: var(--color-status-highlight);
}

.hierarchies {
  margin-block-start: rem(30px);
  border: 1px solid var(--color-border);
}

@media (--handhelds) {
  .hierarchies {
    margin-block-start: 0;
  }
}
