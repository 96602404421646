.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-block-start: var(--spacing-32);
}

.root h3 {
  font-size: var(--font-size-md);
}

.root p {
  margin-block-start: rem(10px);
  max-width: rem(356px);
  text-align: center;
}
