@import '../../../../../styles/animations.css';

.root {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  transition: ease all 0.15s;
  pointer-events: all;
}

.button {
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--color-text-primary);
}

.root ul {
  font-size: var(--font-size-base);
  font-weight: normal;
}

.categoryLink {
  display: block;
  width: 100%;
}

.categoryLink:hover {
  text-decoration: none;
}

[dir='rtl'] .loadingArrow {
  transform: rotate(180deg);
}
