.sectionBody {
  position: relative;
  padding: var(--spacing-16);
  min-height: rem(200px);
}

.sectionBody h3 {
  font-size: var(--font-size-sm);
  margin-block-end: var(--spacing-16);
}

.formInput {
  margin-block-end: var(--spacing-32);
}

input[required] + svg {
  /* hides required "dot" in <InputField> */
  display: none;
}

@media (--min-width-sm) {
  .formInput {
    width: rem(268px);
  }
}
