/* 
  Since the modal content is wrapped in two divs because of the ViewManager, 
  we need to add some custom styling to make it work properly with the new bedrock modal
*/
.modalInnerWrapper {
  display: flex;
  overflow: hidden;
}

.modalContentWrapper[aria-hidden='false'] {
  display: flex !important; /* ViewManager.View sets display: block; this is the only way */
  flex-direction: column;
  width: 100%;
}

.section:not(:last-of-type) {
  margin-block-end: var(--spacing-24);
}

.section:last-of-type {
  margin-block-end: var(--spacing-16);
}

.banner {
  margin-block-end: var(--spacing-16);
}

.section h2 {
  margin-block-end: var(--spacing-16);
}

.titleCount {
  margin-inline-start: var(--spacing-04);
}

.questionSetOption {
  margin-block-end: var(--spacing-16);
  display: flex;
  align-items: center;
  height: 24px;
}

.questionSetOption.dependantOption {
  margin-inline-start: var(--spacing-32);
}

.questionSetOption input[type='checkbox'][aria-disabled='true'] ~ label {
  opacity: 0.5;
}

.questionSetOption svg {
  vertical-align: bottom;
  color: var(--color-icon-decorative);
}

.url {
  word-break: break-word;
}

.disclaimer {
  color: var(--color-text-tertiary);
  margin-block-end: 0;
}

.textWithBadge {
  display: flex;
  align-items: center;
}

.textWithBadge button {
  display: flex;
}

.textWithBadge svg {
  color: var(--color-icon-decorative);
}

.sharingOptions {
  display: flex;
  justify-content: space-between;
}

.sharingOptions svg {
  margin-inline-end: var(--spacing-04);
}

.languageSelector {
  display: flex;
  max-width: 100%;
  margin-block-end: var(--spacing-16);
  justify-content: flex-end;
}

.loadingContainer {
  min-height: 400px;
}

@media (--handhelds) {
  .sharingOptions {
    flex-direction: column;
  }

  .sharingOptions button {
    margin-block-end: var(--spacing-08);
  }
}

.tooltip {
  max-width: 250px;
}
