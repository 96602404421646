@import 'animate.css/source/attention_seekers/rubberBand.css';

.root {
  position: relative;
}

.root.strength {
  border-radius: var(--border-radius-card);
}

.root.suggested:not(:last-child),
.root.strength:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-block-end: 1px solid var(--color-border);
}

.root + .root.suggested,
.root.strength + .root.strength {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.root.large.suggested {
  border-radius: var(--border-radius-card);
  margin-block-end: var(--spacing-08);
}

.root.withNudge {
  margin-block-end: 4px;
}

.root.single,
.root.single .card {
  margin-block-end: 0;
}

/* fist, not large, not single */
.root:nth-child(1):not(.large):not(.single) .card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* not first, not last, not large, not single */
.root:not(:nth-child(1)):not(:last-of-type):not(.large):not(.single) .card {
  border-radius: 0;
}

/* last, not first, not large, not single */
.root:last-child:not(:nth-child(1)):not(.large):not(.single) .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card {
  display: flex;
  flex-direction: column;
  margin-block-end: -1px;
  border-radius: var(--border-radius-card);
}

.list {
  margin-block-end: var(--spacing-16);
}

.root button,
.root a {
  z-index: 1;
  pointer-events: all;
}

.pointerEvents {
  pointer-events: all;
}

.priorityStatusIcon {
  position: absolute !important /* don't let data-hint overwrite this */;
  inset-inline-start: -11px;
  inset-block-start: 22px;
  width: 24px;
  height: 24px;
  z-index: 7;
}

.priorityStatusIcon.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: rubberBand;
}

.inner {
  display: flex;
  place-content: flex-start space-around;
  height: 70px;
  width: 100%;
  align-items: center;
  padding-inline: 20px 10px;
  padding-block: 14px 14px;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.section {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.driverName {
  margin-block-end: 0;
  flex-grow: 0;
  width: 32%;
}

.impact {
  align-self: flex-end;
  color: var(--color-text-tertiary);
  justify-content: center;
  min-height: 40px;
}

.dropdown,
.arrow {
  justify-self: flex-end;
  margin-inline-start: 1px;
}

.dropdownLink {
  display: flex;
  align-items: center;
}

.priorityScore,
.priorityScoreChange {
  display: flex;
  width: 100%;
  padding-inline: 20px;
  padding-block: 12px;
}

.priorityScore svg {
  margin-inline-end: 12px;
  width: 24px;
}

@media (--tablets-min) {
  .impact {
    max-width: 80px;
  }
}

@media (--handhelds) {
  .driverName {
    width: 100%;
    margin-block-end: 10px;
  }

  .dropdownLink {
    margin-block-start: -47px;
  }

  .impact {
    max-width: 40px;
  }

  .inner {
    height: 100%;
    padding-inline: 20px 10px;
    padding-block: 20px 14px;
  }

  .priorityStatusIcon {
    inset-block-start: 20px;
  }
}
