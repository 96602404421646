.loadingContainer {
  padding: var(--spacing-08);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.parentOptionWrapper {
  display: flex;
  flex-direction: column;
}

.directStatus {
  color: var(--color-text-hint);
  font-weight: normal;
}

.parentName {
  margin: 0;
}
