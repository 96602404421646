.attributesListItems {
  margin-block-end: var(--spacing-08);
  border-block-end: 1px solid var(--color-border);
  padding-block-end: var(--spacing-16);
}

.attributesListContainer > div {
  display: grid;
  grid-template-columns: calc(50% - 50px) minmax(0, 1fr) min-content;
  gap: var(--spacing-08);
  align-items: flex-end;
}
