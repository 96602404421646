.root {
  align-items: flex-start;
  box-shadow: inset 0 1px 0 0 var(--color-border);
  color: var(--color-text-primary);
  cursor: pointer;
  display: flex;
  padding-inline: 0.625rem;
  padding-block: 0.9375rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  width: 100%;
}

.root:hover,
.root:active,
.root:visited,
.root:focus {
  color: var(--color-text);
  text-decoration: none;
}

.root:hover,
.root:focus {
  background-color: var(--color-controls-hover-bg);
}

.root.loading {
  pointer-events: none;
}

.root.unread {
  background-color: var(--color-bg-unread);
}

.root.sensitive {
  background-color: var(--color-bg-alert);
}

.root.sensitive p strong {
  color: var(--color-status-negative);
}

.icon {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-inline-end: var(--spacing-16);
  overflow: hidden;
  width: 30px;
}

.icon svg {
  display: block;
}

.content {
  flex: 1;
  padding-block-start: 5px;
}

.text {
  margin-block-end: 10px;
}

.deleted .text {
  text-decoration: line-through;
}

.text p {
  margin: 0;
}

.text :global(.badge) {
  background-color: var(--color-border);
  border-radius: 10px;
  color: var(--color-text-primary);
  font-size: var(--font-size-xs);
  line-height: 1.66;
  padding-inline: 7px;
  padding-block: 1px;
  text-align: center;
}

.footer {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
}

.time {
  align-self: flex-end;
  color: var(--color-text-secondary);
  font-size: var(--font-size-3xs);
  flex-shrink: 0;
}
