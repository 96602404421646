.root {
  flex: 1;
  position: relative;
  width: 100%;
}

.header {
  margin-block-end: 35px;
}

.container {
  margin-inline: auto;
  margin-block: 0;
  max-width: 1030px;
  padding-inline: 20px;
  padding-block: 0 20px;
}

.noResourcesContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.noResourcesHeader {
  margin-inline: 5px 5px;
  margin-block: 15px 0;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.noResourcesInfo {
  margin-inline: 5px 5px;
  margin-block: 5px 20px;
  max-width: 334px;
}

.noResourcesCta {
  margin-inline: 5px 5px;
  margin-block: 5px 10px;
}

.category {
  margin-block-end: 30px;
}

.title {
  display: flex;
  flex-direction: row;
  margin-block-end: 20px;
  align-items: center;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.count {
  color: var(--color-text-tertiary);
}

.jumpTo {
  align-items: center;
  display: flex;
  height: 60px;
}
