.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  max-width: 940px;
  width: 100%;
  margin-inline: auto;
  margin-block: 0;
}

@media (--tablets) {
  .content {
    max-width: calc(100% - var(--spacing-20));
  }
}

.headerTitle {
  padding-inline: 0;
  padding-block: 40px;
}

.headerTitle h1 {
  font-size: var(--font-size-3xl);
  color: var(--color-text-primary);
}

.headerDescription {
  display: flex;
}

.headerDescriptionText span {
  font-size: var(--font-size-md);
  color: var(--color-text-secondary);
}

.headerDescriptionText:first-child {
  padding-inline-end: 20px;
}

.headerDescriptionText:last-child {
  padding-inline-start: 20px;
}

.engagement {
  padding-inline: 0;
  padding-block: 0 40px;
  height: 360px;
}

.engagementHeader {
  display: flex;
  padding-inline: 0 0;
  padding-block: 0 20px;
}

.engagementOverlays {
  height: 254px;
  width: 100%;
  display: flex;
}

.engagementGraphs {
  display: flex;
  position: relative;
  inset-block-start: -130px;
}

.engagementGraphsOverlay {
  display: flex;
  width: inherit;
}

.engagementGraphsOverlayTenure {
  width: calc(100% - 250px);
}

.engagementGraphsTenure {
  width: calc(100% - 270px);
}

.engagementGraphsOverlaySeparation {
  width: calc(230px + 20px);
}

.engagementGraphsSeparation {
  width: 220px;
  position: relative;
  inset-inline-start: 40px;
  margin: 5px;
  inset-block-start: -5px;
}

/* stylelint-disable-next-line selector-class-pattern -- global styling */
.engagementGraphsEmpty :global(.observations-graph__x-axis) {
  display: none;
}

.engagementGraphPhase {
  width: calc(100% / 3);
  height: 254px;
  margin-inline: 0 2px;
  margin-block: 0 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition:
    box-shadow 0.2s ease-in-out 0s,
    transform 0.2s ease-in-out 0s,
    background-color 0.2s ease-in-out 0s;
}

.engagementGraphPhase:hover {
  transform: scale(1.06);
  text-decoration: none;
  z-index: 1;
}

.engagementGraphPhaseSingle {
  width: 100%;
  margin-inline-start: 20px;
}

.engagementGraphPhaseFooter {
  position: relative;
  inset-block-end: 0;
}

.engagementGraphPhaseIcon,
.engagementGraphPhaseIcon img {
  width: 24px;
  height: 24px;
}

.engagementGraphPhaseText {
  font-size: var(--font-size-md);
  color: var(--color-text-primary);
  line-height: 1;
  padding-inline: 0;
  padding-block: 5px 5px;
  font-weight: var(--font-weight-bold);
}

.engagementGraph {
  position: relative;
  inset-block-start: -150px;
  inset-inline-start: -3px;
  height: 150px;
  max-height: 150px;
  width: calc(692px - 15px);
}

.phaseHeader {
  padding-inline: 0;
  padding-block: 0 25px;
}

.engagement h2 div,
.phaseHeader h2 div {
  font-size: var(--font-size-xl);
  margin: 0;
  font-weight: var(--font-weight-bold);
}

.phaseHeader span,
.phaseHeader a {
  font-size: var(--font-size-sm);
}

.phaseHeader span {
  color: var(--color-text-secondary);
}

.separator {
  margin-inline: 0;
  margin-block: 30px;
  position: relative;
  height: 4px;
}

.separatorLine {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: -5px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    transparent 50%,
    var(--color-border-section) 50%
  );
  background-size: 20px 100%;
}

.padBottom {
  padding-inline: 0 0;
  padding-block: 0 135px;
}

.phaseCard:not(:last-child) {
  border-block-end: 1px solid var(--color-border-section);
}

@media (--handhelds) {
  .container h3 > span {
    max-width: 230px;
  }

  .headerTitle {
    padding-inline: 20px;
    padding-block: 10px;
  }

  .headerTitle h1 {
    font-size: rem(26px);
  }

  .headerDescription {
    flex-direction: column;
  }

  .headerDescriptionText:first-child,
  .headerDescriptionText:last-child {
    padding: 0;
  }

  .headerDescriptionText:last-child {
    margin-inline: 0 0;
    margin-block: 10px 20px;
  }

  .headerDescriptionText span {
    font-size: var(--font-size-base);
  }

  .engagement {
    min-height: 648px;
  }

  .engagementHeader {
    padding-inline: 20px 20px;
    padding-block: 0 20px;
  }

  .engagementOverlays,
  .engagementGraphs {
    flex-direction: column;
  }

  .engagementGraphs {
    inset-block-start: -440px;
  }

  .engagementOverlays {
    height: auto;
  }

  .engagementGraphsOverlayTenure,
  .engagementGraphsOverlaySeparation {
    width: 100%;
    margin-inline: 0;
    margin-block: 0 20px;
  }

  .engagementGraphPhaseSingle {
    margin-inline-start: 0;
  }

  .engagementGraphsTenure,
  .engagementGraphsSeparation {
    width: 100%;
  }

  .engagementGraphsSeparation {
    inset-block-start: 114px;
    inset-inline-start: -5px;
  }

  .engagementGraphPhase {
    height: 264px;
    padding: 10px;
    border-radius: 2px;
    margin-inline: 0 1px;
    margin-block: 0 0;
  }

  .engagementGraphPhaseRange {
    display: none;
  }

  .engagementGraphPhaseText {
    font-size: var(--font-size-base);
  }

  .phaseHeader {
    padding-inline: 20px;
    padding-block: 0 10px;
  }

  /* stylelint-disable-next-line selector-class-pattern -- global styling */
  :global(.observations-graph__no-data-text) {
    font-size: var(--font-size-base);
  }

  .nav {
    flex-direction: column;
    height: auto;
    padding-inline: 1em;
    padding-block: 0.3em;
  }

  .nav > h1,
  .nav > div {
    width: 100%;
  }

  .nav button:last-of-type {
    margin-inline-end: 0;
  }
}
