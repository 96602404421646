@import '../../../../../../../styles/animations.css';

.root {
  margin-inline: 0 16px;
  margin-block: 0 0;
  position: absolute;
  inset-inline-end: -4px;
  inset-block-start: 12px;
  transition: ease all 0.15s;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon path {
  fill: var(--color-text-primary);
}

.popper[data-placement^='bottom'] {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  display: block;
  margin-inline: 0 0 !important;
  margin-block: 1px 0 !important;
  width: 158px;
}

.options {
  animation: pop-upwards 0.25s both linear;
  background-color: var(--color-bg-card);
  border: solid 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border-block-start: 0;
  border-block-end: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.23);
}

.option {
  border-block-end: 1px solid var(--color-border);
  cursor: pointer;
  padding: 12px;
  font-size: var(--font-size-xs);
}

.option:hover {
  background-color: var(--color-controls-hover-bg);
}

.option:first-child {
  border-radius: 4px 4px 0 0;
}

.option:last-child {
  border-block-end: 0;
  border-radius: 0 0 4px 4px;
}
