.emptyState {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: auto;
  margin-block: 40px 0;
  max-width: 325px;
  width: 100%;
}

.emptyStateTitle {
  color: var(--color-text);
  margin-block-start: var(--spacing-24);
  font-size: var(--font-size-md);
  text-align: center;
  width: 100%;
}

.emptyStateText {
  text-align: center;
  margin-block-start: 0;
  color: var(--color-text-tertiary);
}

.emptyStateLink {
  text-decoration: underline;
}
