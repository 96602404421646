.header {
  height: 32px !important;
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  border-block-end: 1px solid var(--color-border);
  padding: 0;
  position: relative;
  background-color: var(--color-bg-card);
  width: 100%;
}

.column {
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
  margin-inline-end: 5px;
  padding: 16px;
}

.column.modifiedPadding {
  padding-block: 6px;
}

.column:last-of-type:not(.member) {
  margin-inline-end: 0;
}

.column.member {
  width: rem(250px);
}

.column.segments {
  width: rem(200px);
  gap: 8px;
}

.column.managed,
.column.seen,
.column.notified {
  width: rem(160px);
}

@media (max-width: 1400px) {
  .column.managed,
  .column.seen,
  .column.notified {
    width: rem(80px);
  }
}

.column.actions {
  margin-inline-start: auto;
  justify-content: flex-end;
}

.column.actions > button,
.column.actions button:last-of-type {
  margin-inline-start: 10px;
}

.column a {
  color: var(--color-text-tertiary);
}

.excessManagedSegments {
  display: flex;
  border-radius: 13%;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  background-color: var(--color-viz-neutral-fill);
  /* Pixel values to match the <Avatar/> size */
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  font-size: var(--font-size-sm);
}

@media (--large-screens) {
  .column {
    margin-inline-end: 20px;
  }
}
