.root {
  width: 100%;
  min-height: 320px;
  max-width: 1180px;
  margin-inline: auto;
  margin-block: 0;
  border: solid 1px var(--color-border-section);
  border-radius: var(--border-radius-card);
  box-shadow: var(--depth--1-box-shadow);
  background-color: var(--color-bg-secondary);
  display: flex;
  flex-direction: row;
  padding-inline: 44px;
  padding-block: 40px;
  align-items: center;
}

.description {
  width: 250px;
  margin-inline-end: 35px;
}

.description span {
  display: block;
}

.description span:first-of-type {
  font-size: var(--font-size-xl);
  color: var(--color-text-primary);
  padding-block-end: 5px;
  font-weight: var(--font-weight-bold);
}

.description span:last-of-type {
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
}

.card {
  width: 100%;
  max-width: 816px;
  height: 240px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
  background: var(--color-bg-card);
  position: relative;
}

.card > a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.course {
  display: flex;
  height: 100%;
}

.progress {
  position: absolute;
  display: flex;
  width: 100%;
  inset-block-end: 0;
  padding-inline: 0;
  padding-block: 15px;
  max-width: 300px;
}

.progressLine {
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: rgba(186, 193, 193, 0.3);
  margin-inline: 0 7px;
  margin-block: 0 0;
}

.line {
  height: inherit;
  background-color: var(--color-viz-08);
}

.illustration {
  position: relative;
  min-width: 224px;
  border: solid 5px var(--color-bg-card);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition:
    height 300ms ease-in-out,
    width 300ms ease-in-out;
}

.illustration img {
  max-width: 100px;
}

.label {
  position: absolute;
  z-index: 1;
  display: flex;
  padding: 15px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline-start: 30px;
  padding-block-start: 32px;
}

.driver {
  display: flex;
  align-items: center;
}

.driver span[data-test-id='category-label'] {
  text-transform: uppercase;
}

.title {
  padding-block-start: 10px;
  padding-inline-end: 20px;
}

.meta {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.lessonsMeta,
.durationMeta {
  position: relative;
  display: inline-flex;
  margin-inline-end: 20px;
}

.numberOfLessons {
  align-items: center;
  display: inline-flex;
  font-size: var(--font-size-base);
  color: var(--color-text-tertiary);
}

.cta {
  margin-block: auto 30px;
}

[dir='rtl'] .cta svg {
  transform: rotate(180deg);
}

.title,
.cta {
  z-index: 2;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--color-status-highlight);
  padding-block-start: var(--spacing-32);
}

@media (--tablets) {
  .root {
    flex-direction: column;
    margin-block-end: 20px;
    min-height: 420px;
  }

  .description {
    width: 100%;
    margin-inline-end: initial;
    margin-block-end: 20px;
  }
}

@media (--handhelds) {
  .root {
    flex-direction: column;
    margin-block-end: 20px;
    min-height: 480px;
  }

  .description {
    width: 100%;
    margin-inline-end: initial;
    margin-block-end: 20px;
  }
}
