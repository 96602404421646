@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }

  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}

.root {
  overflow: hidden;
}

.list {
  list-style-type: none;
  padding-inline-start: 0;
  transition: all 0.2s;
}

.list.hidden {
  transform: translateX(-100%);
  visibility: hidden;
}

[dir='rtl'] .list.hidden {
  transform: translateX(100%);
}

.wrapper {
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  border-block-end: 1px solid var(--color-border-section);
}

.wrapper:last-of-type {
  border-block-end: 1px solid transparent;
}

.wrapper.dragOverlay {
  --scale: 1.05;
  --box-shadow: var(--box-shadow);
  --box-shadow-picked-up: var(--text-color),
    -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);

  z-index: 999;
  background-color: var(--color-controls-hover-bg);
}

.item {
  position: relative;
  width: 100%;
  outline: none;
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;
  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
}

.item:focus-visible {
  box-shadow:
    0 0 4px 1px var(--color-border),
    var(--box-shadow);
}

.item:not(.withHandle) {
  touch-action: manipulation;
  cursor: grab;
}

.item.dragging:not(.dragOverlay) {
  opacity: var(--dragging-opacity, 0.5);
  z-index: 0;
}

.item.dragging:focus {
  box-shadow: var(--box-shadow);
}
