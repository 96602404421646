.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin-block-start: -120px;
  width: 100%;
}

.logo {
  margin-block-end: var(--spacing-32);
  width: 120px;
}

.text {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.error {
  text-align: center;
}

.error p {
  color: var(--color-status-negative);
}

.text,
.error p,
.error button {
  margin-block-start: var(--spacing-32);
}
