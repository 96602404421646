.customPreviewContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
}

.content > p {
  text-align: center;
}

.previewItems {
  display: flex;
  gap: var(--spacing-16);
  margin-block-start: var(--spacing-16);
}

.previewCardItem {
  flex: 1;
  display: flex;
  padding: var(--spacing-08);
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  gap: var(--spacing-08);
}

.previewCardItem > svg {
  height: rem(80px);
  width: rem(80px);
}
