.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-block-end: var(--spacing-24);
}

.title {
  font-size: var(--font-size-md);
  margin-block-end: 10px;
  text-align: start;
  font-weight: var(--font-weight-bold);
}
