.formContainer {
  padding: 20px;
}

.row {
  margin-block-end: 15px;
}

.label {
  align-items: center;
  display: flex;
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.alert {
  margin: rem(20px);
}
