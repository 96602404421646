@import 'animate.css/source/flippers/flipInX.css';
@import 'animate.css/source/flippers/flipOutX.css';
@import '../../../../../../../styles/variables.css';
@import '../variables.css';

.container {
  position: sticky;
  inset-block-start: 0;
  /* to cover the thead when scrolling down */
  z-index: calc(var(--thead-z-index-container-width) + 1);
  background-color: var(--color-bg-card);
}

.header {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  border-block-start: 1px solid var(--color-border);
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-start;
  min-height: 63px;
  padding-inline: 16px;
  padding-block: var(--spacing-08);
  position: relative;
}

.headerTitle {
  flex: 1;
  font-size: var(--font-size-md);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.headerActions {
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex: 3;
  justify-content: flex-end;
  gap: var(--spacing-08);
}

.headerBadge {
  display: flex;
  justify-content: flex-end;
}

.select {
  align-items: center;
  background-color: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border);
  display: flex;
  padding-inline: 16px;
  padding-block: 9px;
  z-index: 1;
}

.selectContainer {
  align-items: center;
  display: flex;
  flex: 1;
}

.selectContainer :global(.peakon-select) {
  flex: 2;
}

.selectContainer :global(.peakon-select__control) {
  border-radius: 4px 0 0 4px;
  border-inline-end: 0;
}

.selectContainer > button {
  border-radius: 0 2px 2px 0;
  margin-inline-start: 0;
  height: 42px;
}

.selectCancel {
  margin-inline-start: 10px;
}

.enter,
.exit {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.enter.enterActive {
  animation-name: flipInX;
}

.exit.exitActive {
  animation-name: flipOutX;
}

.option {
  padding-inline-start: var(--spacing-04);
  margin-inline-end: var(--spacing-04);
  font-weight: var(--font-weight-bold);
}
