.root {
  margin-block-end: 75px; /* height of review changes bar, ensures the content can be scrolled up and doesn't get stuck behind the review change bar */
}

.senderSection {
  border-block-end: 1px solid var(--color-border);
}

.senderInput {
  margin-block-end: var(--spacing-32);
}

.sectionTitle {
  margin-block: var(--spacing-32) 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
  line-height: 1.4;
  color: var(--color-text-body);
}

.toggleGroupTitle {
  margin-block-end: var(--spacing-04);
}

.label {
  display: flex;
  flex-direction: row;
  margin-block: var(--spacing-16);
}

.textContent {
  margin-inline-end: var(--spacing-08);
}

.radioHeader {
  display: flex;
}

.radioHeader span {
  margin-block: auto;
  margin-inline-start: var(--spacing-08);
}

.info {
  font-size: var(--font-size-base);
  line-height: 1.4;
  margin-block: 5px 0;
  margin-inline: 0;
  text-align: start;
}

.logo {
  flex: 0 0 100px;
  padding-inline: var(--spacing-16);
  align-self: center;
}

.logo img {
  display: block;
  width: 100%;
}

.logo svg {
  display: block;
  width: 100%;
}

.rowWrap {
  display: flex;
  flex-wrap: wrap;
}

.section {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 10px);
  padding-inline-end: var(--spacing-08);
}

.section:first-child {
  margin-inline-end: var(--spacing-16);
}

.section span {
  margin-block-end: var(--spacing-04);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-body);
  color: var(--color-text-body);
}

.timezoneSelector {
  margin-block-start: var(--spacing-32);
}

.questionLimitSection {
  padding-inline-start: var(--spacing-08);
}

.questionLimitInput {
  margin-block-start: auto;
}

.descriptionWrapper {
  margin-block: rem(12px);
}

.surveySendoutWrapper {
  margin-block-start: var(--spacing-32);
}
